import React from 'react';
import { CourseTableItem } from '../CoursesTable';
import { makeStyles } from '@mui/styles';
import { CourseActionButtons } from '../shared/CourseActionButtons';
import { CourseCapacityBadge } from '../shared/CourseCapacityBadge';

const useStyles = makeStyles(() => ({
    accordionDetailsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        padding: '0 0 14px 8px'
    },
    rightAlign: {
        justifyContent: 'end'
    }
}));

type AccordionDetailsProps = {
    course: CourseTableItem;
    onClick?: () => void;
};

export const AccordionDetails = (props: AccordionDetailsProps) => {
    const { course, onClick } = props;
    const classes = useStyles();
    return (
        <>
            {course.capacityStatus && (
                <div className={classes.accordionDetailsContainer}>
                    <CourseCapacityBadge capacityStatus={course.capacityStatus} />
                </div>
            )}
            <CourseActionButtons {...course} onClick={onClick} dividedButtons={false} />
        </>
    );
};
