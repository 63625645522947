import React from 'react';
import type { CourseTableItem } from '../CoursesTable';
import { makeStyles } from '@mui/styles';
import { ColoredIcon } from 'common';

const useStyles = makeStyles((theme) => ({
    styledImage: {
        width: theme.spacing(4),
        height: theme.spacing(4),

        [theme.breakpoints.down('md')]: {
            width: theme.spacing(5),
            height: theme.spacing(5)
        }
    }
}));

type CourseProductIconProps = {
    url: CourseTableItem['courseIconUrl'];
    color: CourseTableItem['courseIconColor'];
};

export const CourseProductIcon = ({ url, color }: CourseProductIconProps) => {
    const classes = useStyles();
    return <ColoredIcon className={classes.styledImage} url={url} color={color} />;
};
