import React from 'react';
import { makeStyles } from '@mui/styles';
import { CoursesTableProps, CourseTableItem } from '../CoursesTable';
import { Icon, Typography } from 'components';
import { Types } from 'common';

const useStyles = makeStyles((theme) => ({
    courseParticipationContainer: {
        display: 'flex',
        gap: theme.spacing(0.5),
        color: theme.palette.primary.main
    },
    participationIcon: {
        width: theme.spacing(3),
        height: theme.spacing(3)
    },
    participationInfo: {
        fontWeight: 400,
        whiteSpace: 'nowrap'
    },
    participationCity: {
        fontWeight: 700
    },
    onDemandIcon: {
        strokeColor: '#71889D',
        width: '24px',
        strokeWidth: '1px'
    },
    onDemandText: {
        color: theme.palette.primary.main,
        fontWeight: 400,
        fontSize: '16px'
    }
}));

type DesktopCourseParticipationProps = Pick<
    CourseTableItem,
    'participationType' | 'participationCity' | 'participationOptionIconURL'
> &
    Pick<CoursesTableProps, 'variant'>;

export const DesktopCourseParticipation = ({
    participationType,
    participationCity,
    participationOptionIconURL,
    variant
}: DesktopCourseParticipationProps) => {
    const classes = useStyles();

    let participationCityJSX = null;
    let participationTypeText = participationType;
    if (participationCity) {
        participationTypeText = participationType + ' |';
        participationCityJSX = (
            <span className={classes.participationCity}>{participationCity}</span>
        );
    }

    if (variant === Types.ProductBrand.Ondemand || variant === Types.ProductBrand.Syfat) {
        return (
            <div className={classes.courseParticipationContainer}>
                <Icon icon="playCircle2" className={classes.onDemandIcon} />
                <Typography
                    localeId="common.product-type.on-demand"
                    variant="body1"
                    className={classes.onDemandText}
                />
            </div>
        );
    }

    return (
        <div className={classes.courseParticipationContainer}>
            <img className={classes.participationIcon} src={participationOptionIconURL} />
            <span className={classes.participationInfo}>{participationTypeText}</span>
            {participationCityJSX}
        </div>
    );
};
