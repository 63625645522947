import React from 'react';
import type { CourseTableItem } from '../CoursesTable';
import { Button } from 'components';
import { useIntl } from 'react-intl';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { NextLink } from 'common';
import { Box } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    buttonContainerDesktop: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: theme.spacing(2)
    },
    styledButtonDesktop: {
        [theme.breakpoints.down('md')]: {
            height: theme.spacing(3)
        }
    },
    darkerHoverButtonDesktop: {
        '&:hover': {
            backgroundColor: theme.palette.secondary[700],
            borderRadius: 6
        }
    },
    buttonContainer: {
        display: 'flex',
        height: '42px',
        ...theme.border({ width: 1, kind: 'top', color: '#DCDCDC', radius: 0 })
    },
    divider: {
        ...theme.border({ width: 1, kind: 'right', color: '#DCDCDC', radius: 0 }),
        height: '100%'
    },
    styledButton: {
        ...theme.typography.buttonLarge,
        display: 'block',
        padding: 0,
        width: '100%',
        height: '100%',
        textAlign: 'center'
    }
}));

type CourseActionButtonsProps = Pick<
    CourseTableItem,
    'courseURL' | 'bookingURL' | 'bookingButtonClassName' | 'detailsButtonClassName'
> & {
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    dividedButtons?: boolean;
};

export const CourseActionButtons = (props: CourseActionButtonsProps) => {
    const {
        courseURL,
        bookingButtonClassName = '',
        detailsButtonClassName = '',
        bookingURL,
        onClick,
        dividedButtons = false
    } = props;
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onClick && onClick(e);
    };

    return (
        <div className={dividedButtons ? classes.buttonContainerDesktop : classes.buttonContainer}>
            <NextLink
                href={courseURL}
                className={dividedButtons ? classes.styledButtonDesktop : classes.styledButton}
            >
                <Button
                    className={classNames(
                        {
                            [classes.styledButtonDesktop]: dividedButtons,
                            [classes.styledButton]: !dividedButtons
                        },
                        detailsButtonClassName
                    )}
                    variant="text"
                    color="secondary"
                    onClick={handleClick}
                    unstyled
                >
                    {formatMessage({ id: 'catalog.home.agenda.details' })}
                </Button>
            </NextLink>
            {!dividedButtons && <Box className={classes.divider} />}
            <NextLink
                href={bookingURL}
                className={
                    dividedButtons
                        ? classNames(classes.styledButtonDesktop, classes.darkerHoverButtonDesktop)
                        : classes.styledButton
                }
            >
                <Button
                    className={classNames(
                        {
                            [classes.styledButtonDesktop]: dividedButtons,
                            [classes.darkerHoverButtonDesktop]: dividedButtons,
                            [classes.styledButton]: !dividedButtons
                        },
                        bookingButtonClassName
                    )}
                    onClick={handleClick}
                    color="secondary"
                    variant={dividedButtons ? 'contained' : 'text'}
                    unstyled
                >
                    {formatMessage({ id: 'catalog.agenda.row.button.book' })}
                </Button>
            </NextLink>
        </div>
    );
};
