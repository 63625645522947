import React from 'react';
import type { CourseTableItem } from '../CoursesTable';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    cityName: {
        color: theme.palette.primary.main
    }
}));

type CourseParticipationCityProps = Pick<CourseTableItem, 'participationCity'>;

export const CourseParticipationCity = ({ participationCity }: CourseParticipationCityProps) => {
    const classes = useStyles();

    if (!participationCity) {
        return null;
    }

    return (
        <>
            <span>|</span>
            <span className={classes.cityName}>{participationCity}</span>
        </>
    );
};
