import React, { useCallback, useRef, useState } from 'react';
import {
    Accordion,
    AccordionDetails as AccordionDetailsContainer,
    AccordionSummary as AccordionSummaryContainer
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CoursesTableProps, CourseTableItem } from '../CoursesTable';
import { AccordionDetails } from './AccordionDetails';
import { AccordionSummary } from './AccordionSummary';
import { useIntersectionObserver } from 'common';
import { useIntl } from 'react-intl';
import { catalogTracking } from '../../../../../utils/segment';

type AccordionCardStylesProps = {
    backgroundColorExpanded: string;
};

const useStyles = makeStyles((theme) => ({
    styledAccordion: {
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '6px',
        backgroundColor: 'white',
        boxShadow: 'none',

        '&:before': {
            display: 'none'
        },

        '& .MuiAccordionSummary-root': {
            padding: theme.spacing(2, 1)
        },

        '& .MuiAccordionSummary-content': {
            margin: 0,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0
        },

        '&.Mui-expanded': {
            backgroundColor: ({ backgroundColorExpanded }: AccordionCardStylesProps) =>
                backgroundColorExpanded
        }
    },
    styledAccordionSummary: {
        alignItems: 'start',
        color: theme.palette.primary.main
    },
    styledAccordionDetails: {
        margin: 0,
        padding: 0
    }
}));

type AccordionCardProps = Pick<CoursesTableProps, 'variant'> &
    Pick<CoursesTableProps, 'hideMoreCoursesLink'> & {
        course: CourseTableItem;
        onClick?: () => void;
        index: number;
    };

const DEFAULT_BACKGROUND_COLOR_EXPANDED = 'rgba(0, 0, 0, 0.04)';

/**
 * This component renders a course card in the mobile view when viewing courses from multiple products. When this is the case, we need to show the product name, and a product icon, unlike in a single product view.
 */
export const AccordionCard = (props: AccordionCardProps) => {
    const { variant, onClick, course, hideMoreCoursesLink, index } = props;
    const backgroundColorExpanded =
        course.lightBackgroundColor || DEFAULT_BACKGROUND_COLOR_EXPANDED;
    const [isExpanded, setIsExpanded] = useState(false);
    const classes = useStyles({ backgroundColorExpanded });
    const { locale } = useIntl();

    const containerRef = useRef<HTMLDivElement | null>(null);
    useIntersectionObserver(containerRef, {}, () => {
        catalogTracking.courseThumbnailViewed(course.id, { locale, index });
    });

    const handleClick = useCallback(() => {
        setIsExpanded(!isExpanded);
    }, [isExpanded]);

    return (
        <Accordion
            className={classes.styledAccordion}
            disableGutters
            TransitionProps={{ unmountOnExit: true }}
            expanded={isExpanded}
            ref={containerRef}
        >
            <AccordionSummaryContainer
                onClick={handleClick}
                className={classes.styledAccordionSummary}
            >
                <AccordionSummary
                    isAccordionExpanded={isExpanded}
                    hideMoreCoursesLink={hideMoreCoursesLink}
                    course={course}
                    variant={variant}
                />
            </AccordionSummaryContainer>
            <AccordionDetailsContainer className={classes.styledAccordionDetails}>
                <AccordionDetails course={course} onClick={onClick} />
            </AccordionDetailsContainer>
        </Accordion>
    );
};
