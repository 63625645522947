import React from 'react';
import type { CourseTableItem } from '../CoursesTable';
import { makeStyles } from '@mui/styles';
import { LOCALE_TO_DOMAIN } from 'common';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
    styledDatesContainer: {
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '20px',
        whiteSpace: 'nowrap',

        [theme.breakpoints.down(380)]: {
            whiteSpace: 'normal'
        }
    },
    countryCode: {
        color: '#59748D',
        fontSize: 14,
        lineHeight: '20px',
        fontWeight: 700,
        marginRight: 'auto'
    }
}));

type CourseDatesContainerProps = Pick<
    CourseTableItem,
    'courseDates' | 'contentLocale' | 'modulesDuration'
> & {
    showModulesDuration?: boolean;
};

export const CourseDatesContainer = ({
    courseDates,
    contentLocale,
    showModulesDuration,
    modulesDuration
}: CourseDatesContainerProps) => {
    const classes = useStyles();
    const { locale } = useIntl();
    const domain = LOCALE_TO_DOMAIN[locale];
    return (
        <>
            <span className={classes.styledDatesContainer}>
                {showModulesDuration && modulesDuration ? modulesDuration : courseDates}
            </span>
            {contentLocale && domain === 'ch' && (
                <span className={classes.countryCode}>
                    | {contentLocale.substring(0, 2).toUpperCase()}
                </span>
            )}
        </>
    );
};
