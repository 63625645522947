import React from 'react';
import { contentUtils } from '../../../../../utils';
import type { CourseTableItem } from '../CoursesTable';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    creditsContainer: {
        padding: theme.spacing(0, 0.5),
        alignSelf: 'start',
        backgroundColor: (props: { variant: 'collapsed' | 'expanded' }) =>
            props.variant === 'collapsed' ? '#F4F7F9' : 'initial',
        borderRadius: 6
    },
    styledText: {
        fontWeight: 400,
        fontSize: 14,
        color: '#59748D'
    },
    additionalCredits: {
        marginInlineStart: 8,
        fontWeight: 500,
        color: theme.palette.grey[500]
    }
}));

type CourseCreditsProps = Pick<CourseTableItem, 'courseCredits'> & {
    variant: 'collapsed' | 'expanded';
};

export const MobileCourseCredits = ({ courseCredits, variant }: CourseCreditsProps) => {
    const classes = useStyles({ variant });
    const courseCreditArray = courseCredits.split(contentUtils.ContentCreditsSeparator);

    if (variant === 'expanded') {
        return (
            <div className={classes.creditsContainer}>
                <span className={classes.styledText}>{courseCredits}</span>
            </div>
        );
    } else if (variant === 'collapsed') {
        return (
            <div className={classes.creditsContainer}>
                <span className={classes.styledText}>{courseCreditArray[0]}</span>
                {courseCreditArray.length > 1 && (
                    <span className={classes.additionalCredits}>
                        +{courseCreditArray.length - 1}
                    </span>
                )}
            </div>
        );
    }

    return null;
};
