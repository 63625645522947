import React from 'react';
import { catalogTracking } from '../../../../utils/segment';
import { CoursesTableProps, CourseTableItem } from './CoursesTable';
import { makeStyles } from '@mui/styles';
import { AccordionCard } from './mobile/AccordionCard';
import { SingleProductCard } from './mobile/SingleProductCard';
import { useIntl } from 'react-intl';
import {
    BOOKING_URLS,
    buildURLWithParams,
    CATALOG_URLS,
    Types,
    useMembershipContext
} from 'common';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
    mobileTableContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1)
    }
}));

export const MobileTable = ({
    courses,
    variant,
    hideMoreCoursesLink,
    className
}: CoursesTableProps) => {
    const classes = useStyles();
    const { locale } = useIntl();
    const { isMember } = useMembershipContext();
    const MobileCardComponent =
        variant === 'MULTIPRODUCT' ||
        variant === Types.ProductBrand.Ondemand ||
        variant === Types.ProductBrand.Syfat ||
        variant === Types.ProductBrand.SpecialistCertificate
            ? AccordionCard
            : SingleProductCard;

    const handleClick = (course: CourseTableItem, index: number) => {
        if (course.contentType !== Types.ContentType.OnDemandAccredited) {
            catalogTracking.courseClicked(course, { locale, index });
        }
    };

    const generateBookingUrl = (course: CourseTableItem): string => {
        if (course.contentType !== Types.ContentType.OnDemandAccredited) {
            return course.bookingURL;
        }
        if (isMember) {
            return buildURLWithParams(CATALOG_URLS.onDemandAccreditationCourse, [course.id]);
        }
        return BOOKING_URLS.memberships;
    };

    return (
        <div className={classnames(classes.mobileTableContainer, className)}>
            {courses.map((course, index) => (
                <MobileCardComponent
                    key={course.id}
                    hideMoreCoursesLink={hideMoreCoursesLink}
                    course={{
                        ...course,
                        bookingURL: generateBookingUrl(course)
                    }}
                    variant={variant}
                    onClick={() => handleClick(course, index)}
                    index={index}
                />
            ))}
        </div>
    );
};
