import React from 'react';
import { useIsDesktop, Types } from 'common';

import { MobileTable } from './MobileTable';
import { DesktopTable } from './DesktopTable';
import type { OnDemandCoursesQuery } from '../../../../graphql/catalog/queries';
import type { ContentDocumentFields } from '../../../../utils/content';

export interface CourseTableItem {
    id: Types.UUID;
    contentId: string;
    courseIconUrl: string;
    brand: Types.ProductBrand | null | undefined;
    courseIconColor: string | null;
    courseTitle: {
        title: string;
        brand: string;
    };
    courseDates: string;
    topicId: string | null;
    productURL: string;
    subtitle?: string | null;
    courseURL: string;
    contentType: Types.ContentType;
    totalCoursesForProduct: string;
    participationType: string;
    participationCity: string | null;
    courseCredits: string;
    participationOptionIconURL: string;
    capacityStatus: OnDemandCoursesQuery['courses']['data'][number]['capacityStatus'];
    bookingURL: string;
    contentLocale: Locale | undefined;
    segmentFields: Pick<
        ContentDocumentFields,
        'brand' | 'city' | 'contentType' | 'specialities' | 'product' | 'title' | 'contentId'
    >;
    modulesDuration?: string;
    numberOfVideos?: number;
    bookingButtonClassName?: string;
    detailsButtonClassName?: string;
    lightBackgroundColor?: string;
}

export interface CoursesTableProps {
    courses: Array<CourseTableItem>;
    variant:
        | 'MULTIPRODUCT'
        | 'SINGLEPRODUCT'
        | typeof Types.ProductBrand.Ondemand
        | typeof Types.ProductBrand.Syfat
        | typeof Types.ProductBrand.SpecialistCertificate;
    hideMoreCoursesLink?: boolean;
    className?: string;
}

export const CoursesTable = (props: CoursesTableProps) => {
    const isDesktop = useIsDesktop();

    if (isDesktop) {
        return <DesktopTable {...props} />;
    } else {
        return <MobileTable {...props} />;
    }
};
