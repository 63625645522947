import React, { useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { catalogTracking } from '../../../../utils/segment';
import { CoursesTableProps, CourseTableItem } from './CoursesTable';
import { CourseActionButtons } from './shared/CourseActionButtons';
import { DesktopCourseCredits } from './desktop/DesktopCourseCredits';
import { DesktopCourseParticipation } from './desktop/DesktopCourseParticipation';
import { CourseOtherSchedulesLink } from './shared/CourseOtherSchedulesLink';
import { CourseCapacityBadge } from './shared/CourseCapacityBadge';
import { CourseTitle } from './desktop/CourseTitle';
import { DesktopCourseVideos } from './desktop/DesktopCourseVideos';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';
import { LOCALE_TO_DOMAIN, Types, useIntersectionObserver, useTenantContext } from 'common';
import classnames from 'classnames';

type DesktopTableStyles = {
    hoverBackgroundColor?: string;
};

const useStyles = makeStyles(
    (theme) => ({
        styledTable: {
            width: '100%',
            borderCollapse: 'collapse',

            '& td': {
                paddingBlock: theme.spacing(1.5),
                color: theme.palette.primary.main,
                height: 64
            },

            '& tr': {
                cursor: 'pointer',

                '&:hover > td': {
                    backgroundColor: ({ hoverBackgroundColor }: DesktopTableStyles) =>
                        hoverBackgroundColor || 'rgba(0, 0, 0, 0.04)'
                }
            }
        },
        datesColumn: {
            fontWeight: 700
        },
        rightCell: {
            borderRadius: '0 0.75rem 0.75rem 0',
            paddingInlineEnd: theme.spacing(2)
        },
        leftCell: {
            borderRadius: theme.spacing(1.5, 0, 0, 1.5),
            paddingInlineStart: theme.spacing(3)
        },
        countryCode: {
            color: '#59748D',
            fontSize: 14,
            fontWeight: 700
        }
    }),
    { name: 'DesktopTable' }
);

type DesktopTableRowProps = Pick<CoursesTableProps, 'hideMoreCoursesLink' | 'variant'> & {
    course: CourseTableItem;
    index: number;
};

export const DesktopTableRow = (props: DesktopTableRowProps) => {
    const { course, index, variant, hideMoreCoursesLink } = props;
    const { tenantConfig } = useTenantContext();
    const classes = useStyles({
        hoverBackgroundColor: tenantConfig?.colorSettings?.primaryLight || ''
    });
    const router = useRouter();
    const { locale } = useIntl();

    const containerRef = useRef<HTMLTableRowElement | null>(null);
    useIntersectionObserver(containerRef, {}, () => {
        catalogTracking.courseThumbnailViewed(course.contentId, { locale, index });
    });

    const goToCourse = (course: CourseTableItem, index: number) => {
        if (
            course.contentType !== Types.ContentType.OnDemandAccredited &&
            course.brand !== Types.ProductBrand.Syfat
        ) {
            catalogTracking.courseClicked(course, { locale, index });
        }
        router.push(course.courseURL);
    };

    const domain = LOCALE_TO_DOMAIN[locale];

    const shouldShowCourseTitle =
        variant === 'MULTIPRODUCT' ||
        variant === Types.ProductBrand.Ondemand ||
        variant === Types.ProductBrand.Syfat ||
        variant === Types.ProductBrand.SpecialistCertificate;

    const shouldShowVideos =
        variant === Types.ProductBrand.Syfat ||
        variant === Types.ProductBrand.SpecialistCertificate;

    const shouldShowSubtitle = variant === 'SINGLEPRODUCT';
    return (
        <tr
            data-testid="course-row"
            key={course.id}
            onClick={(event) => {
                if ((event.target as HTMLElement).tagName !== 'A') {
                    goToCourse(course, index);
                }
            }}
            ref={containerRef}
        >
            <td className={classes.leftCell}>
                {shouldShowCourseTitle && <CourseTitle {...course} variant={variant} />}
                {shouldShowSubtitle && course.subtitle && <span>{course.subtitle}</span>}
            </td>
            {variant === 'ONDEMAND' && locale !== 'en-GB' && course.modulesDuration && (
                <td>{course.modulesDuration}</td>
            )}
            {(variant === 'SINGLEPRODUCT' || variant === 'MULTIPRODUCT') && (
                <td className={classes.datesColumn}>{course.courseDates}</td>
            )}
            {!hideMoreCoursesLink && (
                <td>
                    <CourseOtherSchedulesLink {...course} />
                </td>
            )}
            <td>
                <DesktopCourseParticipation {...course} variant={variant} />
                <CourseCapacityBadge capacityStatus={course.capacityStatus} />
            </td>
            <td>
                <DesktopCourseCredits {...course} />
            </td>
            {shouldShowVideos && (
                <td>
                    <DesktopCourseVideos {...course} />
                </td>
            )}
            <td>
                {course.contentLocale && domain === 'ch' && (
                    <span className={classes.countryCode}>
                        {course.contentLocale.substring(0, 2).toUpperCase()}
                    </span>
                )}
            </td>
            <td className={classes.rightCell}>
                <CourseActionButtons {...course} dividedButtons />
            </td>
        </tr>
    );
};

export const DesktopTable = ({
    courses,
    variant,
    hideMoreCoursesLink,
    className
}: CoursesTableProps) => {
    const { tenantConfig } = useTenantContext();
    const classes = useStyles({
        hoverBackgroundColor: tenantConfig?.colorSettings?.primaryLight || ''
    });
    return (
        <table className={classnames(classes.styledTable, className)}>
            <tbody>
                {courses.map((course, index) => (
                    <DesktopTableRow
                        course={course}
                        variant={variant}
                        hideMoreCoursesLink={hideMoreCoursesLink}
                        index={index}
                        key={course.id}
                    />
                ))}
            </tbody>
        </table>
    );
};
